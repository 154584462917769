body {
	font-family: 'Fira Code', monospace;
}

a {
	color: #75BEFF;
}
/* override image gallery styles */
.image-gallery-left-nav, .image-gallery-right-nav {
    padding: 10px!important;
    bottom: 0!important;
    top: auto!important;
    transform: none!important;
    height: 100px!important;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 80px!important;
    width: 50px!important;
}

.image-gallery-icon:hover {
    color: #75BEFF!important;
}

.image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
    background: #75BEFF!important;
}
/*========================*/